$(document).ready(function() {
	var $menuCheckbox = $('header#mainMenu .hamburger input');
	var $body = $('body');
	var $navigation = $('header#mainMenu nav');
	$($menuCheckbox).change(function() {
		if ($(this).is(':checked')) {
			$($body).addClass('menuOpen');
			$($navigation).slideDown(300);
		} else {
			$($body).removeClass('menuOpen');
			$($navigation).slideUp(300);
		}
	});

	if (window.matchMedia('(max-width: 990px)').matches) {
		// Mobile
		var $menuWithSubmenu = $('#mainMenu nav ul li.w-submenu');
	} else {
		// Desktop
		var $menuWithSubmenu = $('#mainMenu nav ul li > ul li.w-submenu');
	}

	$($menuWithSubmenu).on('click', function (e) {
		if(!($(this).attr('data-status') == 'clicked_once')) {
			e.preventDefault();
			$(this).find('ul:first').slideToggle(200);
			$(this).attr('data-status', 'clicked_once');
		}
	});

	$('#search_open').on('click', function(event) {
		event.preventDefault();
		$('.search-modal').addClass('shown');
	});
	$('.close-modal').on('click', function(e) {
		e.preventDefault();
		$('.search-modal').removeClass('shown');
	});

	var button_text = "Zobrazit navigaci";
	$('#static-menu-mobile-trigger').on('click', function (e) {
		e.preventDefault();
		$(this).text(
			button_text == "Skrýt navigaci" ? "Zobrazit navigaci" : "Skrýt navigaci"
		);
		$('#static-submenu').slideToggle(200);
		button_text = $(this).text();
	});

	// Lazy load
	/*function lazyLoad() {
	}*/

	$('.lazy').Lazy();

	// Gallery
	$('a[data-rel^=lightcase]').lightcase();


	//Cookies
	if (localStorage.getItem('cookieSeen') != 'shown') {
		$('.cookies-wrapper').delay('1000').fadeIn();
		//localStorage.setItem('cookieSeen','shown')
	}
	$('.cookie-close').on('click', function(e) {
		e.preventDefault()
		$('.cookies-wrapper').fadeOut();
		localStorage.setItem('cookieSeen', 'shown');
	});

});
