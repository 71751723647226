$('#homepage-slider').slick({
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,

    responsive: [
        {
          breakpoint: 767,
          settings: {
            arrows: false
          }
        }
    ]

});